import { graphql, Link, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { useLocation } from "@reach/router";
import {
  categoryColor,
  convertDate,
  isEmpty,
  latestNews,
} from "../../utils/utils";
import Layout from "../common/Layout";

const NewsDetails = ({ data }) => {
  const { body } = data.mdx;
  const {
    category,
    date,
    imageUrl,
    postedBy,
    title,
    url,
    slug: slugName,
    summary,
    readInMinutes,
    isSharable,
  } = data?.mdx?.frontmatter;

  const categories = [];
  if (data?.categories?.nodes) {
    for (const node of data.categories.nodes) {
      if (!categories.includes(node.childMdx.frontmatter.category)) {
        categories.push(node.childMdx.frontmatter.category);
      }
    }
  }

  const relatedNews = !isEmpty(data?.relatedNews?.nodes)
    ? latestNews(data?.relatedNews?.nodes)
    : [];
  const authorProfile = data?.authorProfile?.frontmatter;
  const categoryColors = data?.categoryColors?.childMdx?.frontmatter;

  const location = useLocation();
  let { origin, pathname } = location;

  return (
    <Layout>
      <div className="news-detail-page">
        <p className="sub-text back-link mb-1">
          <Link to="/news">News</Link>
        </p>
        <div className="row mt-4 mb-3">
          <div className="col-md-8">
            <img src={imageUrl} alt="" className="img-fluid mb-4" />
            <h3>{title}</h3>
            <div className="author text-muted">
              <span id="posted-date">{date ? convertDate(date) : ""}</span>{" "}
              {readInMinutes && <span>| {readInMinutes} minutes read</span>}
            </div>
            <div className="mt-3">
              <MDXRenderer>{body}</MDXRenderer>
            </div>

            {authorProfile && (
              <div className="news-author-profile mt-5 mb-4">
                <div className="row">
                  <div className="col-4 col-sm-3">
                    <img
                      src={authorProfile?.icon?.publicURL}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-8 col-sm-9">
                    <div className="author-content">
                      <h5 className="author-name mt-2">
                        {authorProfile?.name}
                      </h5>
                      <p>{authorProfile?.summary}</p>
                      {authorProfile?.linkedin && (
                        <div className="d-flex justify-content-end align-items-center social-link">
                          <p className="mb-0">
                            <span className="label text-muted">
                              Follow {authorProfile?.name}
                            </span>
                          </p>
                          <a href={authorProfile?.linkedin}>
                            <svg
                              width={28}
                              height={28}
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0)">
                                <path
                                  d="M27.9951 27.9999V27.999H28.0001V20.664C28.0001 17.0757 27.2276 14.3115 23.0326 14.3115C21.0159 14.3115 19.6626 15.4182 19.1101 16.4674H19.0517V14.6465H15.0742V27.999H19.2159V21.3874C19.2159 19.6465 19.5459 17.9632 21.7017 17.9632C23.8259 17.9632 23.8576 19.9499 23.8576 21.499V27.9999H27.9951Z"
                                  fill="#aaaaaa"
                                />
                                <path
                                  d="M8.33008 14.6475H12.4767V28H8.33008V14.6475Z"
                                  fill="#aaaaaa"
                                />
                                <path
                                  d="M10.4017 8C9.07583 8 8 9.07583 8 10.4017C8 11.7275 9.07583 12.8258 10.4017 12.8258C11.7275 12.8258 12.8033 11.7275 12.8033 10.4017C12.8025 9.07583 11.7267 8 10.4017 8V8Z"
                                  fill="#aaaaaa"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect
                                    width={20}
                                    height={20}
                                    fill="white"
                                    transform="translate(8 8)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 mt-1">
            {isSharable && (
              <div className="social-share-links d-flex justify-content-end align-items-center mb-2">
                <div>
                  <div className="text-muted mr-2">Share this article</div>
                </div>
                <a
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${origin}${pathname}%2F&display=popup&ref=plugin&src=like&kid_directed_site=0&app_id=113869198637480`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M21.3308 11.3208H23.1566V8.14083C22.8416 8.0975 21.7583 8 20.4966 8C17.8641 8 16.0608 9.65583 16.0608 12.6992V15.5H13.1558V19.055H16.0608V28H19.6224V19.0558H22.4099L22.8524 15.5008H19.6216V13.0517C19.6224 12.0242 19.8991 11.3208 21.3308 11.3208Z"
                        fill="#aaaaaa"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width={20}
                          height={20}
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${origin}${pathname}`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M27.9951 27.9999V27.999H28.0001V20.664C28.0001 17.0757 27.2276 14.3115 23.0326 14.3115C21.0159 14.3115 19.6626 15.4182 19.1101 16.4674H19.0517V14.6465H15.0742V27.999H19.2159V21.3874C19.2159 19.6465 19.5459 17.9632 21.7017 17.9632C23.8259 17.9632 23.8576 19.9499 23.8576 21.499V27.9999H27.9951Z"
                        fill="#aaaaaa"
                      />
                      <path
                        d="M8.33008 14.6475H12.4767V28H8.33008V14.6475Z"
                        fill="#aaaaaa"
                      />
                      <path
                        d="M10.4017 8C9.07583 8 8 9.07583 8 10.4017C8 11.7275 9.07583 12.8258 10.4017 12.8258C11.7275 12.8258 12.8033 11.7275 12.8033 10.4017C12.8025 9.07583 11.7267 8 10.4017 8V8Z"
                        fill="#aaaaaa"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width={20}
                          height={20}
                          fill="white"
                          transform="translate(8 8)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href={`http://twitter.com/share?text=${title}&url=${origin}${pathname}&hashtags=${category}`}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5154 25.5088C21.5912 25.5088 25.4607 19.6466 25.4607 14.5634C25.4607 14.3969 25.4573 14.2311 25.4498 14.0663C26.2009 13.5232 26.8537 12.8455 27.3687 12.0742C26.6795 12.3807 25.9376 12.5867 25.1596 12.68C25.9538 12.2035 26.5636 11.4502 26.8511 10.5521C26.1079 10.9928 25.2847 11.3129 24.4084 11.4858C23.7065 10.7381 22.7069 10.2705 21.6003 10.2705C19.476 10.2705 17.7532 11.9932 17.7532 14.1168C17.7532 14.4188 17.787 14.7124 17.8529 14.994C14.6557 14.8331 11.8205 13.3025 9.92342 10.9748C9.59319 11.5432 9.40261 12.2036 9.40261 12.9084C9.40261 14.2428 10.0818 15.4212 11.1144 16.1104C10.4833 16.091 9.89051 15.9177 9.37223 15.6294C9.37166 15.6455 9.37166 15.6612 9.37166 15.6784C9.37166 17.5415 10.6976 19.097 12.4581 19.4495C12.1347 19.5375 11.7945 19.5848 11.4436 19.5848C11.1962 19.5848 10.9549 19.5605 10.7206 19.5155C11.2103 21.0439 12.6305 22.1562 14.3143 22.1874C12.9976 23.2194 11.339 23.8341 9.53631 23.8341C9.22626 23.8341 8.91976 23.8163 8.61865 23.7808C10.3212 24.872 12.3428 25.5089 14.5155 25.5089"
                      fill="#aaaaaa"
                    />
                  </svg>
                </a>
              </div>
            )}

            <section className="sidebar-section mb-4">
              <h5 className="title">In Summary</h5>
              <div className="post-intro">{summary}</div>
            </section>

            {categories && categories.length > 0 && (
              <section className="sidebar-section mb-4">
                <h5 className="title">Categories</h5>
                <span
                  className="badge rounded-pill news-badge medium d-inline-block mr-1"
                  style={{
                    backgroundColor: categoryColor(category, categoryColors),
                  }}
                >
                  <Link to={`/news/?category=${category}`}>{category}</Link>
                </span>
              </section>
            )}

            {!isEmpty(relatedNews) && (
              <section className="sidebar-section mb-4">
                <h5 className="title mb-3">Related Recent News</h5>
                {relatedNews.map((news, index) => {
                  if (index <= 5)
                    return (
                      <>
                        {slugName !== news?.childMdx?.frontmatter?.slug && (
                          <div className="row sidebar-news" key={index}>
                            <div className="col-md-4">
                              <Link
                                to={`/news/${news?.childMdx?.frontmatter?.slug}`}
                              >
                                <img
                                  src={news?.childMdx?.frontmatter?.imageUrl}
                                  alt=""
                                  className="img-fluid"
                                />
                              </Link>
                            </div>
                            <div className="col-md-8">
                              <div className="news-title mb-1">
                                <Link
                                  to={`/news/${news?.childMdx?.frontmatter?.slug}`}
                                >
                                  {news?.childMdx?.frontmatter?.title}
                                </Link>
                              </div>
                              <div className="date text-muted text-small">
                                <span id="posted-by">
                                  {news?.childMdx?.frontmatter?.postedBy}
                                </span>{" "}
                                |{" "}
                                <span id="posted-date">
                                  {convertDate(
                                    news?.childMdx?.frontmatter?.date
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                })}
              </section>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default NewsDetails;

export const query = graphql`
  query newsDetail($slug: String, $authorName: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        category
        date
        featureList
        imageAlign
        imageUrl
        postedBy
        title
        url
        summary
        readInMinutes
        isSharable
      }
    }

    categories: allFile(filter: { sourceInstanceName: { eq: "news" } }) {
      nodes {
        childMdx {
          frontmatter {
            category
          }
        }
      }
    }

    relatedNews: allFile(filter: { sourceInstanceName: { eq: "news" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
            imageUrl
            date
            postedBy
            url
            category
            slug
          }
        }
      }
    }
    authorProfile: mdx(frontmatter: { name: { eq: $authorName } }) {
      frontmatter {
        name
        icon {
          publicURL
        }
        slug
        summary
        linkedin
      }
      body
    }
    categoryColors: file(sourceInstanceName: { eq: "categoryColors" }) {
      childMdx {
        frontmatter {
          Partnership
          Products
          Technology
        }
      }
    }
  }
`;
